/* DEMO-SPECIFIC STYLES */

.rotate {
    animation: rotation 35s infinite linear;
    opacity: 0.5;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.typewriter h1 {
    font-family: "Fira Sans", sans-serif;
    font-size: 60px;
    overflow: hidden;
    /* Ensures the content is not revealed until the animation */
    border-right: .15em solid black;
    /* The typwriter cursor */
    white-space: nowrap;
    /* Keeps the content on a single line */
    margin: 0 auto;
    /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em;
    color: #002454;
    /* Adjust as needed */
    animation:
        typing 2s steps(30, end),
        blink-caret .5s step-end infinite;

}

/* The typing effect */
@keyframes typing {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}

/* The typewriter cursor effect */
@keyframes blink-caret {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: orange
    }
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: white;
    position: relative;
    font-family: "Fira Sans", sans-serif;
    /* font-family: sans-serif; */
    /* align-items: center; */
}

.container .header {
    height: 13%;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    position: fixed;
    top: 0px;
    background-color: white;
    font-family: "Fira Sans", sans-serif;
    z-index: 2;
}

.container .header .first-part {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.container .header .first-part .logo {
    width: 50px;
    height: 50px;
}

.container .header .first-part .logo-title {
    height: 50px;
}

.container .header .first-part .knowUs {
    cursor: pointer;
    margin-left: 30px;
}

.container .header .first-part .getSolutions {
    margin-left: 20px;
    cursor: pointer;
}

.container .header .second-part {
    display: flex;
    align-items: center;
}

.container .header .second-part .lang {
    border-radius: 24px;
    width: 80px;
    height: 40px;
    font-weight: 600;
}

.container .header .second-part .lang .ant-select-arrow {
    display: none;
}

.container .header .second-part .contact {
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 16px;
    height: 40px;
    font-weight: 600;
    color: #C400FF;
    border-color: #C400FF;
}

.container .header .second-part .contact:hover {
    background-color: #C400FF;
    color: white;
    border: 1px solid #C400FF;
}

.container .header .second-part .getStarted {
    margin-right: 30px;
    border-radius: 16px;
    background-color: #C400FF;
    color: #FFFFFF;
    height: 40px;
    font-weight: 600;
}

.container .header .second-part .getStarted:hover {
    background-color: #002454;
}

.container .content {
    /* display: flex; */
    width: 100%;
    height: 100%;
    padding-top: 10px;
    margin-top: 75px;
    font-family: "Fira Sans", sans-serif;
}

:where(.css-dev-only-do-not-override-1uq9j6g).ant-carousel .slick-dots-bottom {
    bottom: 243px !important;
}

.container .content .image-content {
    position: absolute;
    top: 50px;
    color: #FFFFFF;
    width: 820px;
    margin-left: 30px;
    text-align: left;
    font-family: "Fira Sans", sans-serif;
}

.container .content .contentStyle {
    /* height: 650px; */
    width: 100%;
    color: '#fff';
    line-height: 160px;
    text-align: center;
    background: white;
}

.container .content .image-content .title {
    font-size: 48px;
    font-weight: 600;
    text-align: left;
}

.container .content .image-content .desc {
    font-size: 36px;
    font-weight: 400;
    text-align: left;
}

.container .content .image-content .image-button {
    height: 48px;
    background-color: #002454;
    color: #FFFFFF;
    border-radius: 16px;
    margin-top: 20px;
    color: #FFFFFF;
    border: 1px solid #002454;
    font-size: 18px;
}

.container .content .image-content .image-button:hover {
    animation: tilt-shaking 0.25s infinite;
}

@keyframes tilt-shaking {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(5deg);
    }

    50% {
        transform: rotate(0eg);
    }

    75% {
        transform: rotate(-5deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.container .content .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.container .content .wrapper .white-box {
    width: 80%;
    text-align: center;
    height: 298px;
    background-color: #FFFFFF;
    z-index: 1;
    margin-top: -210px;
    border-radius: 16px;
    box-shadow: 0px 10px 39px rgb(0 0 0 / 8%);
    padding: 50px;
    margin-bottom: -36px;
    /* height: auto; */
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.container .content .wrapper .white-box .title {
    font-size: 56px;
    font-weight: 600;
    color: #002454;
}

.container .content .wrapper .white-box .sub-title {
    font-size: 42px;
    font-weight: 600;
    margin-top: 50px;
    color: #C400FF;
}

.container .content .wrapper .white-box .desc {
    font-size: 60px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    text-align: center;
    width: 100%;
}

.container .content .wrapper .split-box {
    display: flex;
    height: 500px;
    width: 100%;
    margin-bottom: 50px;
    margin-top: -80px;


}

.container .content .wrapper .split-box .first-part {
    width: 50%;
    background-color: #C400FF;
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    padding-left: 40px;
}

.container .content .wrapper .split-box .first-part .text-part {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 100px;
}

.container .content .wrapper .split-box .first-part .text-part .title {
    color: #FFFFFF;
    font-size: 42px;
    margin-bottom: 30px;
}

.container .content .wrapper .split-box .second-part {
    width: 50%;
    background-color: #002454;
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    padding-left: 40px;
}

.container .content .wrapper .split-box .second-part .text-part {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 100px;
}

.container .content .wrapper .split-box .second-part .text-part .title {
    color: #FFFFFF;
    font-size: 42px;
    margin-bottom: 30px;
}

.container .content .wrapper .split-box .first-part .contact-button {
    margin-bottom: 50px;
    height: 40px;
    width: 200px;
    background-color: #C400FF;
    border: 1px solid #FFFFFF;
    border-radius: 16px;
    transition: width 1s, height 1s;
    font-size: 18px;
    font-weight: 600;

}

.container .content .wrapper .split-box .first-part .contact-button:hover {
    width: 250px;
    height: 50px;
}


.container .content .wrapper .split-box .second-part .contact-button {
    margin-bottom: 50px;
    height: 40px;
    width: 200px;
    background-color: #002454;
    border: 1px solid #FFFFFF;
    border-radius: 16px;
    transition: width 1s, height 1s;
    font-size: 18px;

}

.container .content .wrapper .split-box .second-part .contact-button:hover {
    width: 250px;
    height: 50px;
}

.container .content .wrapper .heading {
    font-size: 60px;
    font-weight: 600;
}

.container .content .wrapper .cards-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    margin-top: 30px;
    margin-bottom: 50px;
}

.container .content .wrapper .cards-section .card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 70px;
    height: 500px;
    /* margin-bottom: 150px; */
}

.container .content .wrapper .cards-section .card .card-image {
    width: 40%;
    border-radius: 8px;
    transition: width 1s, height 1s;
}

.container .content .wrapper .cards-section .card .card-image:hover {
    width: 50%;
}

.container .content .wrapper .cards-section .card .card-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px;
    text-align: left;
    width: 50%;
}

.container .content .wrapper .cards-section .card .card-text .heading {
    font-size: 42px;
    font-weight: 600;
    margin-bottom: 10px;
}

.container .content .wrapper .cards-section .card .card-text .title {
    font-size: 36px;
    font-weight: 600;
}

.container .content .wrapper .cards-section .card .card-text .sub-title {
    font-size: 24px;
    font-weight: 400;
    margin-top: 30px;
}

.container .content .wrapper .bottom-part {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

}

.container .content .wrapper .bottom-part .tech-text {
    position: absolute;
    text-align: center;
    top: 0;
    color: #002454;
    font-size: 112px;
    font-weight: 600;
    margin-top: -5px;
}

.container .content .wrapper .form-section {
    width: 50%;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    border: 1px solid #002454;
    /* height: 600px; */
    padding: 30px;
    margin-top: 50px;
    border-radius: 16px;
    margin-bottom: 50px;
}

.container .content .wrapper .form-section .form-title {
    font-size: 36px;
    font-weight: 600;
    color: #C400FF;
}

.container .content .wrapper .form-section .form-sub-title {
    font-size: 28px;
    font-weight: 600;
    text-align: left;
    margin-top: 30px;
    color: #002454;
}

.container .content .wrapper .form-section .field-section {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    width: 100%;
}

.container .content .wrapper .form-section .field-section .input-field {
    height: 50px;
    border-radius: 8px;
    color: #000;
    background-color: #c4c4c433;
}

.container .content .wrapper .form-section .field-section .select-field {
    height: 50px;
    border-radius: 8px;
    color: #000;
    background-color: #c4c4c433;
    text-align: left;
}

:where(.css-dev-only-do-not-override-1uq9j6g).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #c4c4c433;
}

.container .content .wrapper .form-section .submit-button {
    margin-top: 50px;
    height: 60px;
    border-radius: 8px;
    color: #FFFFFF;
    background-color: #C400FF;
    font-size: 24px;
    font-weight: 600;
}

.ant-input {
    color: #000;
}

.ant-input::placeholder {
    color: #999090;
    /* Change to your desired color */
}

.ant-select-selection-item {
    color: #000;
}

.ant-select-selection-placeholder {
    color: #999090;
    /* Change to your desired color */
}

:where(.css-dev-only-do-not-override-1uq9j6g).ant-select-single.ant-select-show-arrow .ant-select-selection-item,
:where(.css-dev-only-do-not-override-1uq9j6g).ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: #000;
}

.container .content .wrapper .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 200px;
    /* padding: 30px; */
    width: 100%;
    background-color: #002454;
    color: #FFFFFF;
    font-family: "Fira Sans", sans-serif;
}

.container .content .wrapper .footer .first-part {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-top: 30px;
    padding-bottom: 20pxs;

}

.container .content .wrapper .footer .first-part .footer-text {
    margin-top: 30px;
    font-size: 20px
}

.container .content .wrapper .footer .second-part {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    align-items: flex-end;
    gap: 10px;
    margin-right: 30px;
    margin-top: 30px;

}

.whatsappDiv {
    position: fixed;
    right: 0px;
    top: 50%;
    transition: width 1s;
    width: 55px;
    background-color: #002454;
    display: flex;
    color: #000;
    flex-direction: row;
    height: 55px;
    z-index: 5;
    border-radius: 8px;
}

.whatsappDiv:hover {
    width: 230px;

}

.ant-dropdown-menu-item ant-dropdown-menu-item-only-child {
    background-color: #C400FF;
    color: #FFFFFF;
}

:where(.css-dev-only-do-not-override-1uq9j6g).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
    background-color: #C400FF;
    color: #FFFFFF;
}

:where(.css-dev-only-do-not-override-1uq9j6g).ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #C400FF;
    color: #FFFFFF;
}

/* mobile style */
@media only screen and (max-width: 600px) {
    .container {}

    .container .header {
        height: 10%;
        flex-direction: column;
        align-items: flex-start;
        width: 93%;
        padding: 30px 10px 30px 10px;
        gap: 20px;
    }

    .container .header .first-part {
        /* flex-direction: column; */
        justify-content: space-between;
        margin-left: 0px;
        width: 100%;
    }

    .container .header .first-part .logo {
        width: 25px;
        height: 25px;

    }

    .container .header .first-part .logo-title {
        height: 40px;
    }


    .container .content {
        margin-top: 28%;
    }



    .container .content .image-content {
        width: 200px;
    }

    .container .content .image-content .title {
        font-size: 20px;
    }

    .container .content .image-content .desc {
        font-size: 16px;
    }

    .container .content .image-content .image-button {
        height: 40px;
        margin-top: 10px;
    }

    .container .content .wrapper .white-box {
        height: 100px;
        overflow: hidden;
        margin-top: -120px;
        width: 80%;
        padding: 10px;
        padding-top: 40px;
        margin-bottom: -32px;
    }

    .container .content .wrapper .white-box .title {
        font-size: 28px !important
    }

    .container .content .wrapper .white-box .sub-title {
        font-size: 24px
    }

    .container .content .wrapper .white-box .desc {
        font-size: 18px;
        top: -21px;
        justify-content: flex-start;
        flex-direction: column;
        margin-top: 0px;
    }

    .typewriter h1 {
        font-size: 24px;
    }

    .container .content .wrapper .split-box {
        flex-direction: column;
    }

    .container .content .wrapper .split-box .first-part {
        width: 100%;
        padding-left: 0px;
    }

    .container .content .wrapper .split-box .first-part .text-part .title {
        font-size: 20px;
    }

    .container .content .wrapper .split-box .second-part {
        width: 100%;
        padding-left: 0px;
        padding-top: 0px;
    }

    .container .content .wrapper .split-box .second-part .text-part .title {
        font-size: 20px;
    }

    .container .content .wrapper .heading {
        font-size: 28px;
        margin-top: 87px;
    }

    .container .content .wrapper .cards-section .card {
        flex-direction: column;

    }

    .container .content .wrapper .cards-section .card .card-text .card-image {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .container .content .wrapper .cards-section .card .card-text {
        padding: 0px 0px 0px 10px;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .container .content .wrapper .cards-section .card .card-text .heading {
        font-size: 24px;
        margin-top: 0px
    }

    .container .content .wrapper .cards-section .card .card-text .title {
        font-size: 18px;
    }

    .container .content .wrapper .cards-section .card .card-text .sub-title {
        font-size: 14px;
    }

    .container .content .wrapper .cards-section .card {
        margin-top: 0px;
        height: auto;
        margin-bottom: 10px;
    }

    .container .content .wrapper .cards-section {
        width: 90%;
    }

    .container .content .wrapper .bottom-part .tech-text {
        font-size: 30px;
        margin-top: 5px;
    }

    .container .content .wrapper .form-section {
        width: 90%;
        border: none;
    }

    .container .content .wrapper .form-section {
        font-size: 24px
    }

    .container .content .wrapper .form-section .form-sub-title {
        font-size: 20px;
    }

    .container .content .wrapper .form-section .form-sub-title {
        height: 40px;
        font-size: 20px;
    }

    .container .content .wrapper .footer {
        height: auto;
        flex-direction: column;
        font-size: 14px
    }

    .container .content .wrapper .footer .second-part {
        text-align: right;
        margin-bottom: 10px;
    }

    :where(.css-dev-only-do-not-override-1uq9j6g).ant-carousel .slick-dots-bottom {
        bottom: 80px !important;
    }

    .container .content .contentStyle {
        height: 416px;
    }

    .container .content .wrapper .cards-section .card .card-image:hover {
        width: 100%;
    }

}

.image {
   width: 100% !important;
   height: auto !important; 
}